import React, { Fragment } from 'react'
const Error = ({ messages }) => (
  <Fragment>
    {messages.map((e, i, a) => (
      <p key={i} className="mt-2">
        <span className="text-red-700">
          {a.length > 1 ? '\u2022 ' : ''}
          {e?.split('\n').map((ePart, iPart) =>
            iPart > 0 ? (
              <>
                <br />
                {ePart}
              </>
            ) : (
              ePart
            )
          )}
        </span>
      </p>
    ))}
  </Fragment>
)

export default Error
